/*
  FS-Cloud Login APIs

  PINCODE SEND EMAIL
    url: 'https://bff-homol.whitelabel.com.br/authentication/v1/send',
    headers:
      { client_secret: 'acf7ef943fdeb3cbfed8dd0d8f584731',
        client_id: '74be16979710d4c4e7c6647856088456',
        'Content-Type': 'application/json' },
    body: { email: 'lukasz.fokin@fs.com.br' },
------------------------------------------------------------------------------------
  PINCODE SEND SMS
    url: 'https://bff-homol.whitelabel.com.br/authentication/v1/send',
    headers:
      { client_secret: 'acf7ef943fdeb3cbfed8dd0d8f584731',
        client_id: '74be16979710d4c4e7c6647856088456',
        'Content-Type': 'application/json' },
    body: { msisdn: '5510000000000' },
------------------------------------------------------------------------------------
  PINCODE VALIDATE
    url: 'https://bff-homol.whitelabel.com.br/authentication/v1/validate',
    headers:
      { client_secret: 'acf7ef943fdeb3cbfed8dd0d8f584731',
        client_id: '74be16979710d4c4e7c6647856088456',
        'Content-Type': 'application/json' },
    body:
      { transaction_id: '35353130303030303030303030236d736973646e.37346265313639373937313064346334653763363634373835363038383435363a61636637656639343366646562336362666564386464306438663538343733312e3031303230332e313536343037303834382e32313538',
        pincode: '010203' },
------------------------------------------------------------------------------------
  AUTHORIZATION
    url: 'https://bff-homol.whitelabel.com.br/authorization/api/v1/authorize',
    headers:
      { X-FS-Correlation-ID': '312312',
        Authorization: 'Bearer ASDASDASD',
        client_secret: 'acf7ef943fdeb3cbfed8dd0d8f584731',
        client_id: '74be16979710d4c4e7c6647856088456',
        'X-FS-Brand-Name': 'vivob2b',
        'X-FS-Requester-Name': 'postman',
        'Content-Type': 'application/json' },
    body: { 'fs-auth-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtc2lzZG4iOiI1NTEwMDAwMDAwMDAwIiwiZXhwaXJlRGF0ZSI6IjIwMTktMDctMjYgMTY6MDc6NTUifQ.q8Uc-nve23fX1sOELxGNt9o6RoSjFSc1khJZ1QJUD6g' },
------------------------------------------------------------------------------------
*/
import axios from "axios";

export default ({
  deviceId,
  getFsTrackId,
  normalizeUser,
  firebase,
  userUrl,
  pincodeSendUrl,
  proxyLoginEnabled,
  pincodeResendUrl,
  pincodeVerifyUrl,
  authorizeUrl,
  forgotEmailUrl,
  uuid,
  androidLoginClientSecret,
  androidLoginClientId,
  iosLoginClientSecret,
  iosLoginClientId,
  brandName,
  loginClientSecret,
  loginClientId,
  Platform,
  isB2BorB2C,
  addPrivacyConsent
}) => {
  let fsBrandName;
  const actualBrandName = () => (brandName() === "vivo" ? "b2c" : brandName());

  const sendRequest = ({ url, headers, body }) => {
    return new Promise((resolve, reject) => {
      if (!url || !body) {
        throw new Error("url/body not found");
      }
      body["context"] = body["context"] || {};
      body["context"]["brand"] = body["context"]["brand"] || actualBrandName();
      axios
        .post(url, body, { headers })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.log(e);
          if (e.response && e.response.data) {
            let err = void 0;
            let responseData = e.response.data;
            if (responseData.error) {
              if (responseData.error.message === "Subscription not found") {
                err = new Error("Assinatura não encontrada");
                err.code = responseData.error.code || responseData.error.status_code;
              } else {
                err = new Error(responseData.error.message);
                err.code = responseData.error.code || responseData.error.status_code;
              }
            } else if (responseData.message) {
              if (responseData.message === "Subscription not found") {
                err = new Error("Assinatura não encontrada");
                err.code = responseData.code || responseData.status_code;
              } else {
                err = new Error(responseData.message);
                err.code = responseData.code || responseData.status_code;
              }
            } else {
              err = new Error(JSON.stringify(responseData));
            }
            console.error(err, "123123132");

            reject(err);
          } else {
            reject(e);
          }
        });
    });
  };

  const getPlatformWiseClientSecret = () => {
    if (Platform.OS === "ios") {
      return iosLoginClientSecret();
    } else if (Platform.OS === "android") {
      return androidLoginClientSecret();
    } else {
      return loginClientSecret();
    }
  };

  const getPlatformWiseClientId = () => {
    if (Platform.OS === "ios") {
      return iosLoginClientId();
    } else if (Platform.OS === "android") {
      return androidLoginClientId();
    } else {
      return loginClientId();
    }
  };

  const checkProxyMobile = async mobile => {
    return await sendRequest({ url: `${userUrl()}/checkProxyMobileExists`, body: { mobile } });
  };

  const authorizeToken = async (params, mergeToken, fromLandline) => {
    // console.warn("authorizeToken");
    const { transaction_id, "fs-custom-token": fsCustomToken } = params;
    const headers = {
      "X-FS-Correlation-ID": uuid(),
      client_secret: getPlatformWiseClientSecret(),
      client_id: getPlatformWiseClientId(),
      "X-FS-Brand-Name": brandName(),
      "X-FS-Requester-Name": `fscloud-${brandName()}-${Platform.OS}`,
      "Content-Type": "application/json"
    };
    if (Platform.OS !== "web" && getFsTrackId) {
      headers["X-FS-Track-Id"] = getFsTrackId();
    }
    const reqParams = {
      url: authorizeUrl(),
      headers,
      body: {
        transaction_id
      }
    };
    if (fromLandline && brandName() === "vivob2b") {
      reqParams.url = `${authorizeUrl()}?extref_fixo=true`;
    }
    if (mergeToken) {
      reqParams.body["fs-merge-token"] = fsCustomToken;
      reqParams.body["fs-auth-token"] = mergeToken;
    } else {
      reqParams.body["fs-auth-token"] = fsCustomToken;
    }
    // console.warn("reqParmas",reqParams);
    return await sendRequest(reqParams);
  };

  const sendSMS = async params => {
    console.warn("sendSMS");
    let { mobile, smshash, fromLandline } = params;
    if (proxyLoginEnabled) {
      try {
        const { proxy } = await checkProxyMobile(mobile);
        if (proxy) {
          return { transaction_id: "__proxy__" };
        }
      } catch (e) {
        // do nothing
      }
    }
    mobile = `55${mobile}`;

    if (fromLandline && brandName() === "vivo") {
      fsBrandName = "vivofixa";
    } else {
      fsBrandName = brandName();
    }

    let requestParams = { msisdn: mobile };
    if (smshash) {
      requestParams.smshash = smshash;
    }
    const headers = {
      client_secret: getPlatformWiseClientSecret(),
      client_id: getPlatformWiseClientId(),
      "X-FS-Brand-Name": fsBrandName,
      "X-FS-Requester-Name": `fscloud-${fsBrandName}-${Platform.OS}`,
      "Content-Type": "application/json"
    };
    if (fromLandline && isB2BorB2C) {
      // headers["extref_fixo"] = true;
    }
    if (Platform.OS !== "web" && getFsTrackId) {
      headers["X-FS-Track-Id"] = getFsTrackId();
    }
    const reqParams = {
      url: pincodeSendUrl(),
      headers,
      body: requestParams
    };
    //console.log("reqParams: ", reqParams);
    return await sendRequest(reqParams);
  };

  const resendPincode = async ({ transaction_id }) => {
    const headers = {
      client_id: getPlatformWiseClientId(),
      client_secret: getPlatformWiseClientSecret(),
      "X-FS-Requester-Name": `fscloud-${brandName()}-${Platform.OS}`,
      "X-FS-Brand-Name": brandName()
    };
    if (Platform.OS !== "web" && getFsTrackId) {
      headers["X-FS-Track-Id"] = getFsTrackId();
    }
    const reqParams = {
      url: pincodeResendUrl(),
      headers,
      body: { transaction_id }
    };
    return await sendRequest(reqParams);
  };

  const validateOtpProxy = async params => {
    console.warn("validateOtpProxy");
    let { mobile } = params;
    mobile = `+55${mobile}`;
    return await getUserFromCustomToken({ mobile });
  };

  const validateOtp = async params => {
    console.warn("validateOtp");
    const { transaction_id, otp, email, mergeToken, fromLandline, mobile } = params;
    if (proxyLoginEnabled && transaction_id === "__proxy__") {
      console.log("validateOtpProxy");
      return await validateOtpProxy({ mobile });
    }
    const headers = {
      client_secret: getPlatformWiseClientSecret(),
      client_id: getPlatformWiseClientId(),
      "X-FS-Brand-Name": brandName(),
      "X-FS-Requester-Name": `fscloud-${brandName()}-${Platform.OS}`,
      "Content-Type": "application/json"
    };
    if (Platform.OS !== "web" && getFsTrackId) {
      headers["X-FS-Track-Id"] = getFsTrackId();
    }
    const reqParams = {
      url: pincodeVerifyUrl(),
      headers,
      body: {
        transaction_id,
        pincode: otp
      }
    };
    // console.log("otp, reqParams: ", reqParams);
    const validateOtpResult = await sendRequest(reqParams);
    // console.log("otp, validateOtpResult: ", validateOtpResult);
    if (email) {
      return validateOtpResult;
    } else {
      const { authorization } = await authorizeToken(validateOtpResult, mergeToken, fromLandline);
      //console.log("authorization: ", authorization);
      return await getUserFromCustomToken({ ...authorization, fromLandline });
    }
  };

  const getUserFromCustomToken = async params => {
    try {
      // console.warn("getUserFromCustomToken", params);
      const { custom_token, extRefKey } = await sendRequest({
        url: `${userUrl()}/getUserFromCustomToken`,
        body: {
          ...params,
          deviceId,
          skipSuspended: true
        }
      });
      extRefKey && addPrivacyConsent && (await addPrivacyConsent("terms", extRefKey));

      await firebase().auth().signInWithCustomToken(custom_token);
      const user = await firebase().auth().currentUser;
      const userToReturn = await normalizeUser({ user, fromLogin: true });
      return { user: userToReturn };
    } catch (error) {
      console.log("-----------");
      console.log(error);
    }
  };

  const sendEmail = async params => {
    let { email, fromLandline } = params;
    if (fromLandline && brandName() === "vivo") {
      fsBrandName = "vivofixa";
    } else {
      fsBrandName = brandName();
    }
    const headers = {
      client_secret: getPlatformWiseClientSecret(),
      client_id: getPlatformWiseClientId(),
      "X-FS-Brand-Name": fsBrandName,
      "X-FS-Requester-Name": `fscloud-${fsBrandName}-${Platform.OS}`,
      "Content-Type": "application/json"
    };
    if (fromLandline && isB2BorB2C) {
      // headers["extref_fixo"] = true;
    }
    if (Platform.OS !== "web" && getFsTrackId) {
      headers["X-FS-Track-Id"] = getFsTrackId();
    }
    const reqParams = {
      url: pincodeSendUrl(),
      headers,
      body: { email }
    };
    return await sendRequest(reqParams);
  };

  const forgotEmail = async params => {
    let { documentId } = params;
    const url = `${forgotEmailUrl()}/${documentId}`;
    const headers = { "X-FS-Brand-Name": brandName(), "X-FS-Requester-Name": `fscloud-${brandName()}-${Platform.OS}` };
    try {
      const result = await axios.get(url, { headers });
      return result && result.data;
    } catch (e) {
      if (e.response && e.response.data) {
        let err = void 0;
        let responseData = e.response.data;
        if (responseData.error) {
          err = new Error(responseData.error.message);
          err.code = responseData.error.code || responseData.error.status_code;
        } else if (responseData.message) {
          err = new Error(responseData.message);
          err.code = responseData.code || responseData.status_code;
        } else {
          err = new Error(JSON.stringify(responseData));
        }
        throw err;
      } else {
        throw e;
      }
    }
  };

  return {
    sendSMS,
    resendPincode,
    sendEmail,
    validateOtp,
    authorizeToken,
    getUserFromCustomToken,
    forgotEmail
  };
};
