import React from "react";
import DetailCollection from "../collection/DetailCollection";
import { DocsCard } from "../docs/DocsCards";
import ResourceDataObject from "../../dataObjects/ResourceDataObject";
import ResourceActions from "../resource/ResourceActions";
import DocDetailComponentSM from "../docs/DocDetailComponentSM";
import {
  StatusBar,
  Box,
  View,
  withContext,
  ImageViewer,
  getImage,
  theme,
  RowAction,
  getAction,
  I18N,
  resolveMQ,
  getComponent,
  RenderNoData,
  normalizeCollectionItems,
  SlideShowComponent,
  modifyUrls
} from "../../../FsCloudComponent";
import ShareLogoComponent from "../share/ShareLogoComponent";

const { colors, fonts, shadows, bgs } = theme;
const { highlightColor, themeColor } = colors;
const { h9, h16 } = fonts;
const { themeBg } = bgs;

const resourceMetadata = {
  gallery: {
    ComponentSM: ImageViewer,
    ComponentMD: ImageViewer
  },
  doc: {
    ComponentSM: DocDetailComponentSM,
    ComponentMD: ImageViewer
  }
};

const imageViewerHeaderContainerStyleMD = {
  viewStyle: {
    height: 72,
    alignItems: "center",
    backgroundColor: highlightColor,
    padding: 15,
    position: "fixed",
    zIndex: 1,
    top: 0,
    right: 0,
    left: 0,
    ...shadows.imageViewerHeaderShadowMD
  }
};

const getShareHeaderTitle = sharedBy => {
  let title = I18N.t("shared");
  if (sharedBy) {
    title = I18N.t("sharedByName");
    title = title.replace("__name__", sharedBy);
  }
  return title;
};

const imageViewerRenderHeaderMD = ({ value: data, sharedToken }) => {
  return (
    <Box
      render={[
        <Box
          direction="row"
          {...imageViewerHeaderContainerStyleMD}
          render={[
            {
              viewStyle: {
                paddingLeft: 15,
                paddingRight: 15
              },
              textStyle: {
                color: themeColor,
                ...h16
              },
              width: "1fr",
              text: getShareHeaderTitle(data.sharedBy)
            },
            {
              viewStyle: {
                height: 24,
                width: 24,
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 15,
                marginRight: 15,
                cursor: "pointer"
              },
              image: getImage("downloadIcon"),
              Container: RowAction,
              containerProps: {
                data,
                action: { ...ResourceActions.download, sharedToken, visible: true }
              }
            },
            <ShareLogoComponent icon={"headerLogo"} />
          ]}
        />,
        <Box viewStyle={{ height: 72 }} />
      ]}
    />
  );
};

const imageViewerRenderHeaderSM = ({ sharedToken, value: data = {} }) => {
  let renderToShow = [
    {
      viewStyle: { paddingLeft: 12 },
      text: getShareHeaderTitle(data.sharedBy),
      width: "1fr"
    },
    {
      render: (
        <RowAction data={data} action={{ ...ResourceActions.download, sharedToken, visible: true }}>
          <Box
            viewStyle={{
              paddingLeft: 4,
              paddingRight: 4,
              width: 32,
              height: 32,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            }}
            image={getImage("downloadWhiteIcon")}
          />
        </RowAction>
      )
    },
    <ShareLogoComponent
      viewStyle={{
        paddingLeft: 8,
        paddingRight: 8
      }}
      icon={"headerLogo"}
    />
  ];
  return (
    <Box
      direction="row"
      viewStyle={{
        alignItems: "center",
        height: 64,
        paddingRight: 8,
        backgroundColor: "rgba(0,0,0,0.4)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2
      }}
      textStyle={{
        ...h16,
        color: highlightColor,
        numberOfLines: 1
      }}
      render={renderToShow}
    />
  );
};

class DetailCollectionHeaderSM extends React.Component {
  render() {
    let {
      skipTitle,
      user,
      rowData,
      collectionItems,
      selectedIds,
      selectionMode,
      sharedToken,
      origin,
      theme
    } = this.props;
    let { containerStyle, actionStyle, titleStyle } = theme;
    if (this.props.containerStyle) {
      containerStyle = this.props.containerStyle;
    }
    if (this.props.titleStyle) {
      titleStyle = this.props.titleStyle;
    }
    const selectionCount = selectedIds && selectedIds.length ? selectedIds.length : void 0;
    const title = selectionMode
      ? selectionCount
        ? `${selectionCount} ${I18N.t("selected")}`
        : I18N.t("selectItemsDot")
      : skipTitle
      ? ""
      : rowData.system
      ? getShareHeaderTitle(rowData.sharedBy)
      : rowData.name;

    let backAction = void 0;
    if (selectionMode) {
      backAction = {
        image: getImage("backIcon"),
        Container: getAction("unSelectAll")
      };
    }
    return (
      <Box
        {...containerStyle}
        render={[
          backAction && {
            ...actionStyle,
            ...backAction
          },
          {
            ...titleStyle,
            text: title
          },
          <ShareLogoComponent
            viewStyle={{
              paddingLeft: 8,
              paddingRight: 8
            }}
            icon={"headerLogo"}
          />,
          <RowAction
            source="Header"
            data={rowData}
            action={[
              {
                text: I18N.t("be_part_of_it"),
                type: "anchorAction",
                url: rowData.bePartUrl,
                visible: () => {
                  let collabrators = rowData && rowData.collabrators;
                  let alreadyMember = user && collabrators && collabrators.indexOf(user.firebaseUid) !== -1;
                  return !rowData.system && rowData.bePartUrl && !alreadyMember;
                }
              },
              { ...ResourceActions.download, collectionItems, sharedToken, origin, visible: true }
            ]}
          />
        ]}
      />
    );
  }
}

class DetailCollectionHeaderMD extends React.Component {
  render() {
    let {
      skipTitle,
      user,
      rowData,
      collectionItems,
      selectedIds,
      selectionMode,
      origin,
      sharedToken,
      theme
    } = this.props;
    let { containerStyle, actionStyle, titleStyle } = theme;
    titleStyle["textStyle"] = {
      ...titleStyle["textStyle"],
      ...h16,
      color: themeColor
    };
    if (this.props.containerStyle) {
      containerStyle = this.props.containerStyle;
    }
    if (this.props.titleStyle) {
      titleStyle = this.props.titleStyle;
    }
    const selectionCount = selectedIds && selectedIds.length ? selectedIds.length : void 0;
    let title = selectionMode
      ? selectionCount
        ? `${selectionCount} ${I18N.t("selected")}`
        : I18N.t("selectItemsDot")
      : skipTitle
      ? ""
      : rowData.system
      ? getShareHeaderTitle(rowData.sharedBy)
      : rowData.name;

    let backAction = void 0;
    if (selectionMode) {
      backAction = {
        image: getImage(origin === "album" ? "crossWhiteIcon" : "crossIcon"),
        Container: getAction("unSelectAll")
      };
    }

    let bePartAction = void 0;
    let collabrators = rowData && rowData.collabrators;
    let alreadyMember = user && collabrators && collabrators.indexOf(user.firebaseUid) !== -1;
    if (!rowData.system && rowData.bePartUrl && !alreadyMember) {
      const LinkBox = getComponent("anchor");
      bePartAction = (
        <LinkBox style={{ textDecoration: "none" }} target="_self" url={rowData.bePartUrl}>
          <Box
            viewStyle={{
              height: 36,
              marginLeft: 12,
              marginRight: 12,
              borderRadius: 4,
              paddingLeft: 12,
              paddingRight: 12,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: themeBg
            }}
            textStyle={{
              ...h9,
              color: highlightColor
            }}
            text={I18N.t("be_part_of_it")}
          />
        </LinkBox>
      );
    }

    return (
      <Box
        {...containerStyle}
        render={[
          backAction && {
            ...actionStyle,
            ...backAction
          },
          {
            ...titleStyle,
            text: title
          },
          {
            ...actionStyle,
            image: getImage(origin === "set" ? "downloadIcon" : "downloadWhiteIcon"),
            Container: RowAction,
            containerProps: {
              data: rowData,
              action: { ...ResourceActions.download, collectionItems, sharedToken, origin, visible: true }
            }
          },
          bePartAction,
          <ShareLogoComponent icon={origin === "set" ? "headerLogo" : "headerLogoWhiteText"} />
        ]}
      />
    );
  }
}

const noDataStyle = {
  headerContainerStyleMD: imageViewerHeaderContainerStyleMD,
  headerContainerStyleSM: {
    viewStyle: {
      alignItems: "center",
      height: 60,
      backgroundColor: highlightColor,
      ...shadows.navHeaderShadowSM
    }
  },
  titleStyleMD: {
    viewStyle: {
      paddingLeft: 15
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  emptyBoxStyleMD: {
    viewStyle: { height: 72 }
  },
  titleStyleSM: {
    viewStyle: {
      paddingLeft: 12
    },
    textStyle: {
      color: themeColor,
      ...h16
    }
  },
  logoStyleSM: {
    viewStyle: {
      paddingLeft: 8,
      paddingRight: 8
    },
    icon: "headerLogo"
  },
  logoStyleMD: {
    icon: "headerLogo"
  }
};

class ShareNoDataComponent extends React.Component {
  render() {
    const noDataProps = {
      image: "shareNoDataIcon",
      primaryText: I18N.t("shareNoDataHeader"),
      secondaryText: I18N.t("shareNoDataMessage")
    };
    const { activeMQ } = this.props;
    const { headerContainerStyle, emptyBoxStyle, titleStyle, logoStyle } = resolveMQ(
      noDataStyle,
      ["headerContainerStyle", "emptyBoxStyle", "titleStyle", "logoStyle"],
      activeMQ
    );
    return (
      <View style={{ flex: 1 }}>
        <Box
          direction="row"
          {...headerContainerStyle}
          render={[
            {
              ...titleStyle,
              width: "1fr",
              text: () => I18N.t("sharingPage")
            },
            <ShareLogoComponent {...logoStyle} />
          ]}
        />
        <Box {...emptyBoxStyle} />
        <RenderNoData {...noDataProps} />
      </View>
    );
  }
}

ShareNoDataComponent = withContext(ShareNoDataComponent, { activeMQ: "ActiveMQ" });

const SHARE_DATA_LIMIT = 200;
class SharedData extends React.Component {
  state = {};
  pending = true;

  fetchInRecursion = async ({ sharedToken, result, skip }) => {
    let { invoke, urls } = this.props;
    let uriProps = {
      data: { token: sharedToken, limit: SHARE_DATA_LIMIT }
    };
    if (skip) {
      uriProps.data.skip = skip;
    }

    let invokeResult = await invoke({
      service: {
        url: urls["getSharedData"],
        uriProps
      }
    });

    if (!invokeResult || !invokeResult.collectionItems) {
      return result ? result : invokeResult;
    }

    result = result
      ? { ...result, collectionItems: [...result.collectionItems, ...invokeResult.collectionItems] }
      : invokeResult;
    if (invokeResult.collectionItems.length === SHARE_DATA_LIMIT) {
      skip = invokeResult.collectionItems[invokeResult.collectionItems.length - 1];
      result = await this.fetchInRecursion({ sharedToken, result, skip });
    }
    return result;
  };

  loadData = async () => {
    try {
      const { hashMap = {}, connectSetState } = this.props;
      const { sharedToken } = hashMap;
      if (!sharedToken) {
        this.pending = false;
        return;
      }
      this.pending = true;
      let result = await this.fetchInRecursion({ sharedToken });

      modifyUrls && modifyUrls(result, { sharedToken });
      this.pending = false;
      this.setState({ data: result });
      connectSetState &&
        result &&
        connectSetState({
          data: result.collectionItems || []
        });
    } catch (err) {
      this.pending = false;
      this.setState({ error: err.message });
    }
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { data = {}, error } = this.state;
    const { activeMQ, hashMap = {} } = this.props;
    const { sharedToken, sharedMontage } = hashMap;
    if (error) {
      return <ShareNoDataComponent />;
    }
    if (this.pending) {
      return <StatusBar />;
    }
    let renderComponent = null;
    if (sharedMontage && data) {
      let albumLink = this.props.link.uri.replace("montage-view", "my-shared-data");
      renderComponent = (
        <SlideShowComponent
          sharedToken={sharedToken}
          decryptionSourceProps={{ sharedToken, decryptionSource: origin === "album" && "collection" }}
          backToAlbum={true}
          albumLink={albumLink}
          images={data.collectionItems}
          name={data.collection && (data.collection.system ? getShareHeaderTitle(data.sharedBy) : data.collection.name)}
          albumId={data.collection && data.collection._id}
        />
      );
    } else if (data.hasOwnProperty("resource") && data["resource"]["_id"]) {
      let resourceData = data["resource"];
      let origin = void 0;
      if (resourceData["type"] === "image" || resourceData["type"] === "video") {
        origin = "gallery";
      } else if (resourceData["type"] === "doc") {
        origin = "doc";
      }
      // resourceData["uri"] = resourceData["converted_jpeg_url"] || resourceData["thumbnail_url"];
      resourceData["height"] = resourceData["converted_height"] || resourceData["thumbnail_height"];
      resourceData["width"] = resourceData["converted_width"] || resourceData["thumbnail_width"];
      resourceData["url"] = resourceData["converted_jpeg_url"] || resourceData["thumbnail_url"];
      resourceData = { ...resourceData, sharedBy: data.sharedBy };
      let componentProps = {
        fromShare: true,
        sharedToken,
        images: [resourceData],
        data: [resourceData],
        detailFromProps: true,
        detailId: resourceData._id,
        index: 0,
        decryptionSourceProps: { sharedToken },
        renderHeaderMD: props =>
          imageViewerRenderHeaderMD({
            ...props,
            sharedToken
          })
      };
      if (origin === "gallery") {
        componentProps = {
          ...componentProps,
          renderHeaderSM: props =>
            imageViewerRenderHeaderSM({
              ...props,
              sharedToken
            })
        };
      }
      if (origin) {
        let { Component } = resolveMQ(resourceMetadata[origin], ["Component"], activeMQ);
        renderComponent = <Component origin={origin} {...componentProps} onClose={() => {}} />;
      }
    } else if (
      data.hasOwnProperty("collection") &&
      data["collection"]["_id"] &&
      data["collectionItems"] &&
      Array.isArray(data["collectionItems"]) &&
      data["collectionItems"].length
    ) {
      let collectionData = data["collection"];
      collectionData = { ...collectionData, sharedBy: data.sharedBy };
      let origin = collectionData["type"];
      let collectionItems = normalizeCollectionItems({
        result: data["collectionItems"],
        collectionId: collectionData && collectionData._id
      });

      const { DetailCollectionHeader } = resolveMQ(
        {
          DetailCollectionHeaderSM,
          DetailCollectionHeaderMD
        },
        ["DetailCollectionHeader"],
        activeMQ
      );
      let componentProps = {
        data: collectionItems,
        link: {
          props: { sharedToken, params: { collection: collectionData } },
          origin
        },
        fromShare: true,
        sharedBy: getShareHeaderTitle(collectionData.sharedBy),
        DetailCollectionHeader: props => <DetailCollectionHeader {...props} sharedToken={sharedToken} />
      };

      if (origin === "album") {
        componentProps = {
          ...componentProps,
          listProps: {
            imageField: "thumbnail_url",
            imageHeight: "thumbnail_height",
            imageWidth: "thumbnail_width",
            addMinHeight: 56,
            rowProps: {
              imageField: "thumbnail_url"
            },
            detailLink: ({ data }) => {
              return {
                uri: `/album-gallery-detail`,
                detailId: data._id,
                decryptionSourceProps: { sharedToken },
                props: {
                  params: {
                    sharedToken,
                    collection: data.collection
                  },
                  parentInfo: { _connectProps: { data: collectionItems } }
                },
                fromShare: true,
                origin: "album"
              };
            }
          }
        };
      } else if (origin === "set") {
        componentProps = {
          ...componentProps,
          listProps: {
            list: "scrollIndicatorList",
            indicator_field: "resource_lastModified",
            detailLink: ({ data }) => {
              return {
                uri: `/set-doc-detail`,
                detailId: data._id,
                decryptionSourceProps: { sharedToken },
                props: {
                  params: {
                    sharedToken,
                    collection: data.collection
                  },
                  parentInfo: { _connectProps: { data: collectionItems } }
                },
                fromShare: true,
                origin: "set"
              };
            }
          },
          rowProps: DocsCard,
          schema: ResourceDataObject["Resource"]
        };
      }
      renderComponent = <DetailCollection {...componentProps} />;
    } else {
      renderComponent = <ShareNoDataComponent />;
    }
    return renderComponent;
  }
}

SharedData = withContext(SharedData, {
  invoke: "App.invoke",
  urls: "App.urls",
  selectedIds: "SelectionStore.selectedIds",
  selectionMode: "SelectionStore.selectionMode",
  activeMQ: "ActiveMQ",
  connectSetState: "Screen.Connect.setState"
});

export default {
  SharedData: {
    Component: SharedData,
    component: "custom",
    panelProps: {
      showHeader: false
    },
    hideNavHeader: true
  }
};
