import { Platform } from "react-components";
export var globalApp = void 0;
export var resetFuncion = void 0;
export const setGlobal = app => (globalApp = app);
export const setResetApp = func => (resetFuncion = func);

const context = {
  "SECONDARY_APP": {
    REACT_APP_PROJECT_ID: "fs-cloud-oi",
    REACT_APP_X_FS_Brand_Name: "oi",
    REACT_APP_AUTH_DOMAIN: "fs-cloud-oi.firebaseapp.com",
    REACT_APP_DATABASE_URL: "https://fs-cloud-oi.firebaseio.com",
    REACT_APP_STORAGE_BUCKET: "fs-cloud-oi.appspot.com",
    REACT_APP_MESSAGING_SENDER_ID: "975019086999",
    REACT_APP_API_ID: "AIzaSyCFPSVYSMQ_FeGjYVsgmaSsqdlQsDcQ_lI",
    REACT_APP_FRONTEND_URL: "https://sync.oiseguranca.com.br",
    REACT_APP_METADATA_URL: "/metadata",
    REACT_APP_USER_BASE_URL: "/user",
    REACT_APP_UPLOAD_BASE_URL: "/uploader",
    REACT_APP_SHARE_URL: "/share",
    REACT_APP_DOWNLOAD_ZIP_URL: "/downloader",
    REACT_APP_STREAM_URL: "/streaming",
    REACT_APP_PINCODE_SEND_URL: "https://bff.whitelabel.com.br/authentication/v1/send",
    REACT_APP_PINCODE_RESEND_URL: "https://bff.whitelabel.com.br/authentication/v1/resend",
    REACT_APP_PINCODE_VERIFY_URL: "https://bff.whitelabel.com.br/authentication/v1/validate",
    REACT_APP_AUTHORIZE_URL: "https://bff.whitelabel.com.br/authorization/api/v1/authorize",
    REACT_APP_FORGOT_EMAIL_URL: "https://bff.whitelabel.com.br/bff/api/v2/user/recover-email",
    REACT_APP_LOGIN_CLIENT_SID: "8684f7dbb06fb0f4eaf024bedae4345c",
    REACT_APP_LOGIN_CLIENT_ID: "4a64e977de3038a1d863f147b97e3719",
    REACT_APP_LOGIN_ANDROID_CLIENT_SID: "10af51907de8b580d3cdeff664cfc97a",
    REACT_APP_LOGIN_ANDROID_CLIENT_ID: "fc40764705cd1de1858189e8c47f84f6",
    REACT_APP_LOGIN_IOS_CLIENT_SID: "c19d293e31cf46d9beda778a73bcbfc8",
    REACT_APP_LOGIN_IOS_CLIENT_ID: "eab4e373d8632c2e7899a74f24d87e83",
    REACT_APP_GOOGLE_APP_ID: "1:975019086999:web:545dfc165b29c896",
    REACT_APP_GOOGLE_ANDROID_APP_ID: "1:975019086999:android:7cdce7cb3c672668",
    REACT_APP_GOOGLE_IOS_APP_ID: "1:975019086999:ios:3518cf5824bdb330",
    REACT_APP_GOOGLE_MEASUREMENT_ID: "G-TSTDKR2TXG",
    REACT_APP_PRIVACY_REGISTRY_TERMS_HASH: "7c65daa7bce8b93e5f3f6f614c3d8e1220bc1ac669de087c006ee456922606bc",
    REACT_APP_PRIVACY_REGISTRY_CAMERA_HASH: "1c66f2989957a70b69c3416a60a7276cdf6630c1654f6914b7991beec6ff6b71",
    REACT_APP_PRIVACY_REGISTRY_CONTACTS_HASH: "fe8652c0055509bb00cb545a7673c1e5c08e1de5661249e27de072a1fdce30cf",
    REACT_APP_PRIVACY_REGISTRY_BIOMETRICS_HASH: "b539d4c59baafdc00c30830f0c752d7ad44293a0d8abfd4d7b5f8b18da6272ac",
    REACT_APP_PRIVACY_REGISTRY_LOCATION_HASH: "4eeec2bce9262f6e06d59b942b7611829c1a549ad7cb87cc7f3bd688051cda06",
    REACT_APP_PRIVACY_REGISTRY_STORAGE_HASH: "7b5806788ad8cb25402ccd6467c9ee9cc1685d9d5e1ac9c657799d6196743a5b",
    REACT_APP_ANDROID_MAP_API_KEY: "AIzaSyBZrM5cBM3fyiU-2o0m5pG5Pa23NSKyOy0",
    REACT_APP_IOS_MAP_API_KEY: "AIzaSyA6cr-B1TZ5hOpSdbD_DZzOzybwoabFBQM",
    REACT_APP_PROXY_ENABLED: "true",
    SKIP_PREFLIGHT_CHECK: "true"
  },
  "[DEFAULT]": {
    REACT_APP_PROJECT_ID: "fs-cloud-hero",
    REACT_APP_X_FS_Brand_Name: "hero",
    REACT_APP_AUTH_DOMAIN: "fs-cloud-hero.firebaseapp.com",
    REACT_APP_DATABASE_URL: "https://fs-cloud-hero.firebaseio.com",
    REACT_APP_STORAGE_BUCKET: "fs-cloud-hero.appspot.com",
    REACT_APP_MESSAGING_SENDER_ID: "911089821999",
    REACT_APP_API_ID: "AIzaSyAIVdfzXsFyKueeX-I6jNqZh7bzJxw550w",
    REACT_APP_FRONTEND_URL: "https://cloud-app.fshero.com.br",
    REACT_APP_METADATA_URL: "/metadata",
    REACT_APP_USER_BASE_URL: "/user",
    REACT_APP_UPLOAD_BASE_URL: "/uploader",
    REACT_APP_SHARE_URL: "/share",
    REACT_APP_DOWNLOAD_ZIP_URL: "/downloader",
    REACT_APP_STREAM_URL: "/streaming",
    REACT_APP_PINCODE_SEND_URL: "https://bff.whitelabel.com.br/authentication/v1/send",
    REACT_APP_PINCODE_RESEND_URL: "https://bff.whitelabel.com.br/authentication/v1/resend",
    REACT_APP_PINCODE_VERIFY_URL: "https://bff.whitelabel.com.br/authentication/v1/validate",
    REACT_APP_AUTHORIZE_URL: "https://bff.whitelabel.com.br/authorization/api/v1/authorize",
    REACT_APP_FORGOT_EMAIL_URL: "https://bff.whitelabel.com.br/bff/api/v2/user/recover-email",
    REACT_APP_LOGIN_CLIENT_SID: "9c74d2c744026d9c8e6c9e88aeed0c81",
    REACT_APP_LOGIN_CLIENT_ID: "dc3aeca6d2833f47345e55f3bd7ab257",
    REACT_APP_LOGIN_ANDROID_CLIENT_SID: "d37ffc7ca905d21bedfe4266f5082e08",
    REACT_APP_LOGIN_ANDROID_CLIENT_ID: "7c89bc6bf35de1beff84c0a5d12e8ff4",
    REACT_APP_LOGIN_IOS_CLIENT_SID: "75680d138386730bb38448513fb0eeed",
    REACT_APP_LOGIN_IOS_CLIENT_ID: "613779db3d08abd7e3e17c8535619c70",
    REACT_APP_GOOGLE_APP_ID: "1:911089821999:web:13e21f294464ae91",
    REACT_APP_GOOGLE_ANDROID_APP_ID: "1:911089821999:android:f706b9db161779b5",
    REACT_APP_GOOGLE_IOS_APP_ID: "1:911089821999:ios:7e4eb6bcc999e720",
    REACT_APP_GOOGLE_MEASUREMENT_ID: "G-P5EBEGLF6V",
    REACT_APP_PRIVACY_REGISTRY_TERMS_HASH: "34433584b6247f66f16c13dd2fdd4f51f5c9c6c12e752559ef9aed671597f9c5",
    REACT_APP_PRIVACY_REGISTRY_CAMERA_HASH: "c476c7f473e121edcb731d387e6fcbb5a2ade3b9cb40c91ab8c409cd0e3456ab",
    REACT_APP_PRIVACY_REGISTRY_CONTACTS_HASH: "f655ac33617635d698dc5d39ac80c8c6b2196f38553e0e818ac6120ec65e8c1d",
    REACT_APP_PRIVACY_REGISTRY_BIOMETRICS_HASH: "4812d0a7548858e45f4ff5ff9a7a4dab2f4accfed9f9e08c5fae96c884346a4d",
    REACT_APP_PRIVACY_REGISTRY_LOCATION_HASH: "e034187e3f064f1bca1ce442e79764275e6f77d82420095bc8f66a8a9b0af10b",
    REACT_APP_PRIVACY_REGISTRY_STORAGE_HASH: "40a57e69491592bf0ce486ef97aa0d380ab705d6785cda81177e4852d60d41d4",
    REACT_APP_ANDROID_MAP_API_KEY: "AIzaSyDW2q9UlWkIH3q-21Ys4vsq4ZoeRItQrZY",
    REACT_APP_IOS_MAP_API_KEY: "AIzaSyDauzQxFUh2l4CvbwyWYmbFtw4KabXhPQs",
    REACT_APP_PROXY_ENABLED: "true",
    SKIP_PREFLIGHT_CHECK: "true"
  },
  "TERTIARY_APP": {
    REACT_APP_PROJECT_ID: "fs-cloud-tim-248615",
    REACT_APP_X_FS_Brand_Name: "tim",
    REACT_APP_AUTH_DOMAIN: "fs-cloud-tim-248615.firebaseapp.com",
    REACT_APP_DATABASE_URL: "https://fs-cloud-tim-248615.firebaseio.com",
    REACT_APP_STORAGE_BUCKET: "fs-cloud-tim-248615.appspot.com",
    REACT_APP_MESSAGING_SENDER_ID: "152113248378",
    REACT_APP_API_ID: "AIzaSyCbSC5JRil2PG2svn5Abom3eBevahyle4E",
    REACT_APP_FRONTEND_URL: "https://backup.timprotect.com.br",
    REACT_APP_METADATA_URL: "/metadata",
    REACT_APP_USER_BASE_URL: "/user",
    REACT_APP_UPLOAD_BASE_URL: "/uploader",
    REACT_APP_SHARE_URL: "/share",
    REACT_APP_DOWNLOAD_ZIP_URL: "/downloader",
    REACT_APP_STREAM_URL: "/streaming",
    REACT_APP_PINCODE_SEND_URL: "https://bff.whitelabel.com.br/authentication/v1/send",
    REACT_APP_PINCODE_RESEND_URL: "https://bff.whitelabel.com.br/authentication/v1/resend",
    REACT_APP_PINCODE_VERIFY_URL: "https://bff.whitelabel.com.br/authentication/v1/validate",
    REACT_APP_AUTHORIZE_URL: "https://bff.whitelabel.com.br/authorization/api/v1/authorize",
    REACT_APP_FORGOT_EMAIL_URL: "https://bff.whitelabel.com.br/bff/api/v2/user/recover-email",
    REACT_APP_LOGIN_CLIENT_SID: "f44184e3f19829ec4ff68030bf645382",
    REACT_APP_LOGIN_CLIENT_ID: "dcdf443cdedbe998654259e8e0b20145",
    REACT_APP_LOGIN_ANDROID_CLIENT_SID: "ea8525934c4c43565362ffd2a8026717",
    REACT_APP_LOGIN_ANDROID_CLIENT_ID: "a4a82c81ed10315e641687181e6c2859",
    REACT_APP_LOGIN_IOS_CLIENT_SID: "eddae0857ae933fc684708b79962f95c",
    REACT_APP_LOGIN_IOS_CLIENT_ID: "74a031193a9e9f1cc96c11ea1678b99d",
    REACT_APP_GOOGLE_APP_ID: "1:152113248378:web:6dd7537b8434ef99",
    REACT_APP_GOOGLE_ANDROID_APP_ID: "1:152113248378:android:d00eaff5db8fdee1",
    REACT_APP_GOOGLE_IOS_APP_ID: "1:152113248378:ios:d00eaff5db8fdee1",
    REACT_APP_GOOGLE_MEASUREMENT_ID: "G-WZT957NPB0",
    REACT_APP_PRIVACY_REGISTRY_TERMS_HASH: "6f7d1d98275a3b81f631589b367501c84cc50db9ea430400f2154143697fc830",
    REACT_APP_PRIVACY_REGISTRY_CAMERA_HASH: "0060911f58d9a5ad49d9cd2c1e0c4fac6c29f22e9d5f396f9fb21526296c7f9c",
    REACT_APP_PRIVACY_REGISTRY_CONTACTS_HASH: "c50603cc2f6f7218022b83f6c966c0b61a046bf34afa1e99c3042dafd13793fa",
    REACT_APP_PRIVACY_REGISTRY_BIOMETRICS_HASH: "7ca503f5933221fce51dec4aa1fe615bd768f402548b552344080b0276ae8b1d",
    REACT_APP_PRIVACY_REGISTRY_LOCATION_HASH: "ada35b70ef8cf8fea44fd199347b8648c730d41cbe7a3c470a886b2838ec1a5f",
    REACT_APP_PRIVACY_REGISTRY_STORAGE_HASH: "78e7321422a71479d1a6c974bc9988a1073e43d1b8f4aaaee7d6a2fd8acc0d9c",
    REACT_APP_ANDROID_MAP_API_KEY: "AIzaSyD9TPWHdSbQayPcMdluYhIswrLAsl20_C4",
    REACT_APP_IOS_MAP_API_KEY: "AIzaSyDzIY-JNXhB_hHHHbDOrCokORNPthykzBQ",
    REACT_APP_PROXY_ENABLED: "true",
    SKIP_PREFLIGHT_CHECK: "true"
  }
};

// const isLogEnv = () => context[globalApp].REACT_APP_LOG_ENV;

const frontendUrl = () => {
  let url = context[globalApp].REACT_APP_FRONTEND_URL;

  try {
    if (Platform.OS === "web" && window.location.hostname !== "localhost") {
      url = window.location.origin;
    }
  } catch (e) {}

  return url;
};

let metadataBaseURL = () => {
  const BaseURL = context[globalApp].REACT_APP_METADATA_URL;
  if (!BaseURL.startsWith("http")) {
    return frontendUrl() + BaseURL;
  }
  return BaseURL;
};

let shareBaseURL = () => {
  const baseUrl = context[globalApp].REACT_APP_SHARE_URL;
  if (!baseUrl.startsWith("http")) {
    return frontendUrl() + baseUrl;
  }
  return baseUrl;
};

let uploadBaseURL = () => {
  const baseUrl = context[globalApp].REACT_APP_UPLOAD_BASE_URL;
  if (!baseUrl.startsWith("http")) {
    return frontendUrl() + baseUrl;
  }
  return baseUrl;
};

function userBaseURL() {
  const baseUrl = context[globalApp].REACT_APP_USER_BASE_URL;
  if (!baseUrl.startsWith("http")) {
    return frontendUrl() + baseUrl;
  }
  return baseUrl;
}

let streamBaseURL = () => {
  const baseUrl = context[globalApp].REACT_APP_STREAM_URL;
  if (!baseUrl.startsWith("http")) {
    return frontendUrl() + baseUrl;
  }
  return baseUrl;
};

function downloadBaseURL() {
  const baseURL = context[globalApp].REACT_APP_DOWNLOAD_ZIP_URL;
  if (!baseURL.startsWith("http")) {
    return frontendUrl() + baseURL;
  }
  return baseURL;
}
// isLogEnv &&
//   console.log("FS_CLOUD_ENV's INITIAL:: ", {
//     metadataBaseURL,
//     shareBaseURL,
//     uploadBaseURL,
//     userBaseURL,
//     streamBaseURL,
//     downloadBaseURL
//   });

// if (!metadataBaseURL.startsWith("http")) {
//   metadataBaseURL = frontendUrl + metadataBaseURL;
// }
// if (!shareBaseURL.startsWith("http")) {
//   shareBaseURL = frontendUrl + shareBaseURL;
// }
// if (!uploadBaseURL.startsWith("http")) {
//   uploadBaseURL = frontendUrl + uploadBaseURL;
// }
// if (!streamBaseURL.startsWith("http")) {
//   streamBaseURL = frontendUrl + streamBaseURL;
// }
// if (!userBaseURL.startsWith("http")) {
//   userBaseURL = frontendUrl + userBaseURL;
// }
// if (!downloadBaseURL.startsWith("http")) {
//   downloadBaseURL = frontendUrl + downloadBaseURL;
// }

// isLogEnv &&
//   console.log("FS_CLOUD_ENV's FINAL:: ", {
//     metadataBaseURL,
//     shareBaseURL,
//     uploadBaseURL,
//     userBaseURL,
//     streamBaseURL,
//     downloadBaseURL
//   });

let apiKey = () => context[globalApp].REACT_APP_API_ID;
let authDomain = () => context[globalApp].REACT_APP_AUTH_DOMAIN;
let databaseURL = () => context[globalApp].REACT_APP_DATABASE_URL;
let projectId = () => context[globalApp].REACT_APP_PROJECT_ID;
let storageBucket = () => context[globalApp].REACT_APP_STORAGE_BUCKET;
let messagingSenderId = () => context[globalApp].REACT_APP_MESSAGING_SENDER_ID;
let measurementId = () => context[globalApp].REACT_APP_GOOGLE_MEASUREMENT_ID;

var config = () => {
  return {
    apiKey: apiKey(),
    authDomain: authDomain(),
    databaseURL: databaseURL(),
    projectId: projectId(),
    storageBucket: storageBucket(),
    messagingSenderId: messagingSenderId(),
    measurementId: measurementId()
  };
};
// if (measurementId) {
//   config.
// }

export const fetchUrl = metadataBaseURL;
export const postUrl = metadataBaseURL;
export const socketUrl = void 0;
export const uploadUrl = uploadBaseURL;
export const userUrl = () => {
  const baseUrl = context[globalApp].REACT_APP_USER_BASE_URL;
  if (!baseUrl.startsWith("http")) {
    return frontendUrl() + baseUrl;
  }
  return baseUrl;
};
export const cqrs = true;
export const firebaseAuth = true;
export const firebaseUploadFile = true;
export const firebaseConfig = config;
export const downloadZipURL = downloadBaseURL;

// google app ids
export const googleAppId = () => context[globalApp].REACT_APP_GOOGLE_APP_ID;
export const googleAndroidAppId = () => context[globalApp].REACT_APP_GOOGLE_ANDROID_APP_ID;
export const googleIosAppId = () => context[globalApp].REACT_APP_GOOGLE_IOS_APP_ID;

// added for login-sdk
export const proxyLoginEnabled = () => context[globalApp].REACT_APP_PROXY_ENABLED;
export const pincodeSendUrl = () => context[globalApp].REACT_APP_PINCODE_SEND_URL;
export const pincodeResendUrl = () => context[globalApp].REACT_APP_PINCODE_RESEND_URL;
export const pincodeVerifyUrl = () => context[globalApp].REACT_APP_PINCODE_VERIFY_URL;
export const authorizeUrl = () => context[globalApp].REACT_APP_AUTHORIZE_URL;
export const forgotEmailUrl = () => context[globalApp].REACT_APP_FORGOT_EMAIL_URL;
export const brandName = () => context[globalApp].REACT_APP_X_FS_Brand_Name;
export const loginClientSecret = () => context[globalApp].REACT_APP_LOGIN_CLIENT_SID;
export const loginClientId = () => context[globalApp].REACT_APP_LOGIN_CLIENT_ID;
export const androidLoginClientSecret = () => context[globalApp].REACT_APP_LOGIN_ANDROID_CLIENT_SID;
export const androidLoginClientId = () => context[globalApp].REACT_APP_LOGIN_ANDROID_CLIENT_ID;
export const iosLoginClientSecret = () => context[globalApp].REACT_APP_LOGIN_IOS_CLIENT_SID;
export const iosLoginClientId = () => context[globalApp].REACT_APP_LOGIN_IOS_CLIENT_ID;
export const REGISTRY_HASHES = {
  terms: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_TERMS_HASH,
  camera: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_CAMERA_HASH,
  contacts: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_CONTACTS_HASH,
  location: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_LOCATION_HASH,
  storage: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_STORAGE_HASH,
  biometrics: () => context[globalApp].REACT_APP_PRIVACY_REGISTRY_BIOMETRICS_HASH
};

export const googleMapUrl = () => `https://maps.googleapis.com/maps/api/js?key=${apiKey()}`;
export const privacyConsentUrl = () => `https://dpo.privacytools.com.br/public_api/consent/`;
export const firestorePostUrl = () =>
  `https://firestore.googleapis.com/v1/projects/${projectId()}/databases/(default)/documents:runQuery`;

export const urls = {
  markAsDelete: () => `${metadataBaseURL()}/markDelete`,
  checkCollectionExists: () => `${metadataBaseURL()}/checkCollectionExists`,
  createCollection: () => `${metadataBaseURL()}/createCollection`,
  removeItemFromCollection: () => `${metadataBaseURL()}/removeItem`,
  addItemToCollection: () => `${metadataBaseURL()}/addItem`,
  renameCollection: () => `${metadataBaseURL()}/renameCollection`,
  renameResource: () => `${metadataBaseURL()}/renameMetadata`,
  removeCollection: () => `${metadataBaseURL()}/removeCollection`,
  updateAlbumCover: () => `${metadataBaseURL()}/updateCoverCollection`,
  getSharedData: () => `${shareBaseURL()}/sharedData`,
  getLink: () => `${shareBaseURL()}/getToken`,
  createGroup: () => `${shareBaseURL()}/createGroup`,
  addGroupMembers: () => `${shareBaseURL()}/addGroupMembers`,
  removeGroup: () => `${shareBaseURL()}/removeGroup`,
  removeGroupMember: () => `${shareBaseURL()}/removeGroupMember`,
  leftGroupMember: () => `${shareBaseURL()}/leftGroupMember`,
  addGroupItem: () => `${shareBaseURL()}/addGroupItem`,
  groupLink: () => `${shareBaseURL()}/groupLink`,
  removeGroupItem: () => `${shareBaseURL()}/removeGroupItem`,
  removeInvitationMember: () => `${shareBaseURL()}/removeInvitationMember`,
  updateUserProfile: () => `${userBaseURL()}/updateUserProfile`,
  removeProfilePic: () => `${userBaseURL()}/removeProfilePic`,
  setDeviceInfo: () => `${userBaseURL()}/setDeviceInfo`,
  exportFile: () => `${shareBaseURL()}/exportFile`,
  getUserQuota: () => `${uploadUrl()}/fetch`,
  isContactPicUploaded: () => `${uploadBaseURL()}/isContactPicUploaded`,
  bePartOfCollection: () => `${shareBaseURL()}/bePart`,
  searchMetadata: () => `${metadataBaseURL()}/search`,
  markPrivate: () => `${shareBaseURL()}/markPrivate`,
  collaborators: () => `${shareBaseURL()}/collaborators`,
  acceptInvitation: () => `${shareBaseURL()}/acceptInvitation`,
  leftSharedCollection: () => `${shareBaseURL()}/leftSharedCollection`,
  updateColloboratorMode: () => `${metadataBaseURL()}/updateColloboratorMode`,
  profilePic: () => `${metadataBaseURL()}/profilePic`,
  contactPic: () => `${metadataBaseURL()}/contactPic`,
  shareViaEmail: () => `${shareBaseURL()}/sharedByMail`,
  currentUserData: () => `${userBaseURL()}/getUserData`,
  unmarkDeleteMultiple: () => `${metadataBaseURL()}/unmarkDeleteMultiple`,
  removeResource: () => `${metadataBaseURL()}/removeResource`,
  removeAllResource: () => `${metadataBaseURL()}/removeAllResource`,
  removeNonUploaded: () => `${metadataBaseURL()}/removeNonUploaded`,
  addContact: () => `${userBaseURL()}/createContact`,
  updateContact: () => `${userBaseURL()}/updateContact`,
  markContactsDeleted: () => `${userBaseURL()}/markContactsDeleted`,
  checkDeleted: () => `${metadataBaseURL()}/checkForTrash`,
  getDuplicateContact: () => `${userBaseURL()}/getDuplicateContact`,
  restoreContacts: () => `${userBaseURL()}/restoreContacts`,
  removeDuplicateContact: () => `${userBaseURL()}/removeDuplicateContact`,
  searchContacts: () => `${userBaseURL()}/search`,
  lockContactSync: () => `${userBaseURL()}/lockContactSync`,
  unlockContactSync: () => `${userBaseURL()}/unlockContactSync`,
  picOfDay: () => `${userBaseURL()}/getPictureOfDay`,
  exportPictureOfDay: () => `${userBaseURL()}/exportPictureOfDay`,
  discardPictureOfDay: () => `${userBaseURL()}/discardPictureOfDay`,
  createVaultPin: () => `${userBaseURL()}/createVaultPin`,
  authenticateVault: () => `${userBaseURL()}/authenticateVault`,
  updateVaultPin: () => `${userBaseURL()}/updateVaultPin`,
  resetVaultPin: () => `${userBaseURL()}/resetVaultPin`,
  authenticateVaultPinToken: () => `${userBaseURL()}/authenticateVaultPinToken`,
  authenticateBlockToken: () => `${userBaseURL()}/authenticateBlockToken`,
  resetVaultPinUsingLink: () => `${userBaseURL}/resetVaultPinUsingLink`,
  forgotVaultPin: () => `${userBaseURL}/forgotVaultPin`,
  requestLinkToBlockRestAPI: () => `${userBaseURL()}/requestLinkToBlock`,
  blockDeviceRestAPI: () => `${userBaseURL()}/blockDevice`,
  blockDeviceUsingLinkApi: () => `${userBaseURL()}/blockDeviceUsingLink`,
  markFavourite: () => `${metadataBaseURL()}/markFavourite`,
  unmarkFavourite: () => `${metadataBaseURL()}/unmarkFavourite`,
  moveToVault: () => `${metadataBaseURL()}/moveToVault`,
  removeFromVault: () => `${metadataBaseURL()}/removeFromVault`,
  montageSounds: () => `${shareBaseURL()}/sounds`,
  thumbnail_url: () => `${metadataBaseURL()}/thumbnail_url`,
  converted_jpeg_url: () => `${metadataBaseURL()}/cover_url`,
  converted_mp4_url: () => `${metadataBaseURL()}/video_url`,
  resource_url: () => `${metadataBaseURL()}/resource_url`,
  converted_mp3_url: () => `${metadataBaseURL()}/audio_url`,
  shared_thumbnail_url: () => `${metadataBaseURL()}/shared/thumbnail_url`,
  shared_converted_jpeg_url: () => `${metadataBaseURL()}/shared/cover_url`,
  shared_converted_mp4_url: () => `${metadataBaseURL()}/shared/video_url`,
  shared_resource_url: () => `${metadataBaseURL()}/shared/resource_url`,
  uploadError: () => `${metadataBaseURL()}/errorInUpload`,
  migrationUrl: () => `${userBaseURL()}/migrationQueueStatus`,
  createIssueUrl: () => `${shareBaseURL()}/createIssue`,
  updateIssueUrl: () => `${shareBaseURL()}/updateIssue`,
  refreshStatsUrl: () => `${shareBaseURL()}/refreshStats`,
  refreshAllStatsUrl: () => `${shareBaseURL()}/refreshAllStats`,
  reProcessUrl: () => `${shareBaseURL()}/reProcess`,
  fsMigrationStatusUrl: () => `${shareBaseURL()}/fsMigrationStatus`,
  fsMigrationProcessUrl: () => `${shareBaseURL()}/fsMigrationProgress`,
  getAllReportedIssuesUrl: () => `${shareBaseURL()}/getAllReportedIssues`,
  markDoneIssueUrl: () => `${shareBaseURL()}/markDoneIssue`,
  addCommentByUserUrl: () => `${shareBaseURL()}/addCommentByUser`,
  addCommentBySupportUrl: () => `${shareBaseURL()}/addCommentBySupport`,
  getCommentsUrl: () => `${shareBaseURL()}/getComments`,
  migrationNotificationAcceptedUrl: () => `${userBaseURL()}/migrationNotificationAccepted`,
  sendLogsToServerUrl: () => `${shareBaseURL()}/sendDeviceLogs`,
  toggleTroubleshootingLogsUrl: () => `${userUrl()}/enableLogs`,
  removeDuplicateFiles: () => `${metadataBaseURL()}/removeDuplicateFiles`,
  updateResourceDeviceId: () => `${metadataBaseURL()}/updateResourceDeviceId`,
  fetchEncKeyIV: () => `${userBaseURL()}/getUsersEncKey`,
  getAppWarnings: () => `${userBaseURL()}/getWarnings`,
  placeCollection: () => `${metadataBaseURL()}/placeAggregate`,
  deviceFolderCollection: () => `${metadataBaseURL()}/deviceFolderAggregate`,
  streamVideo: () => `${streamBaseURL}/streamVideo`,
  streamAudio: () => `${streamBaseURL}/streamAudio`,
  removeStreamToken: () => `${streamBaseURL}/removeToken`,
  dataStreaming: () => `${streamBaseURL}/streamData`,
  downloadZip: () => `${downloadBaseURL()}/download`,
  downloadZipAll: () => `${downloadBaseURL()}/downloadAll`,
  downloadZipKey: () => `${downloadBaseURL()}/downloadKey`,
  removeFailedResources: () => `${metadataBaseURL()}/removeFailedResource`,
  removeAllFailedResources: () => `${metadataBaseURL()}/removeAllFailedResources`,
  moveToArchive: () => `${metadataBaseURL()}/markArchive`,
  removeFromArchive: () => `${metadataBaseURL()}/unmarkArchive`,
  saveAsPdfWeb: () => `${uploadBaseURL()}/generatePDF`,
  saveAsGif: () => `${uploadBaseURL()}/generateGif`
};
