import { Platform } from "react-components";

const APP_VERSION = "2.39.0";
const APP_BUILD_VERSION = Platform.OS === "ios" ? "2.39.0" : Platform.OS === "android" ? "2.39.0" : APP_VERSION;
const APP_TITLE = "EXA Cloud";

const APP_SHORT_NAME = "EXA Cloud";
const APP_LINK = "https://hero-cloud.whitelabel.com.br";
const APP_HELP_URL = "https://fshero.com.br/ajuda-cloud";
const APP_TERMS_CONDITION_URL = "https://dpo.privacytools.com.br/policy-view/DPX3x1gdx/3/policy/pt_BR?s=1711471022714";
const APP_TALK_TO_US_URL = "https://exa.com.br/faq";
const APP_ANDROID_URL = "https://play.google.com/store/apps/details?id=br.com.fshero.androidsync";
const APP_IOS_URL = "https://apps.apple.com/br/app/cloud-by-hero/id1031344497";
const APP_CHAT_URL = "http://bit.ly/Hero_App_Unico";
const APP_MIGRATION_CHAT_URL = "http://s.fs.com.br/ajudaCloudHERO";
const APP_WINDOWS_DOWNLOAD_LINK = "https://cs.whitelabel.com.br/hero/clients/fscloud/fscloud-installer.exe";
const APP_MAC_DOWNLOAD_LINK = "https://cs.whitelabel.com.br/hero/clients/fscloud/fscloud-installer.dmg";
export default {
  APP_VERSION,
  APP_BUILD_VERSION,
  APP_TITLE,
  APP_SHORT_NAME,
  APP_LINK,
  APP_HELP_URL,
  APP_TERMS_CONDITION_URL,
  APP_TALK_TO_US_URL,
  APP_ANDROID_URL,
  APP_IOS_URL,
  APP_CHAT_URL,
  APP_MIGRATION_CHAT_URL,
  APP_WINDOWS_DOWNLOAD_LINK,
  APP_MAC_DOWNLOAD_LINK
};
